<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#3F033B"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showsnackbar" color="#3F033B" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12>
        <v-layout wrap>
          <v-flex xs10 text-left>
            <span class="mainHeader">GOLD SCHEME</span>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 py-6 text-left>
        <v-card
          tile
          elevation="0"
          class="rounded-lg"
         
          :color="$route.name == 'Dashboard' ? 'transparent' : '#FFFFFF'"
          :flat="$route.name == 'Dashboard' ? true : false"
        >
          <v-layout wrap pa-4>
            <v-flex xs12 md8 style="font-family: DmSansBold; font-size: 25px"
              >ENLISTING</v-flex
            >
            <v-flex xs12>
              <v-card
                class="rounded-lg"
                elevation="0"
                :color="$route.name == 'Dashboard' ? 'transparent' : '#FFFFFF'"
                :flat="$route.name == 'Dashboard' ? true : false"
              >
                <v-layout wrap pa-4>
                  <v-flex xs12 md10 sm10>
                    <v-layout wrap justify-space-between>
                      <v-flex xs12 md5 sm5 py-2>
                        
                        <v-flex xs12 md5 sm12 pb-1 class="subhead3">Customer Name</v-flex>
  
                        <v-autocomplete
                          color="#b234a9"
                          :items="customerlist"
                          v-model="custName"
                          placeholder="Select Customer"
                          item-text="supplierName"
                          item-value="_id"
                          outlined
                          hide-details
                          dense
                        ></v-autocomplete>
                      </v-flex>
                      

                      <v-flex xs12 md5 sm5 py-2>
                        <v-flex xs12 md5 sm12 pb-1 class="subhead3">Scheme Name</v-flex>
                        <v-autocomplete
                          color="#b234a9"
                          v-model="scName"
                          placeholder="Select Scheme"
                          item-text="supplierName"
                          item-value="_id"
                          outlined
                          hide-details
                          dense
                        ></v-autocomplete>
                      </v-flex>
                      <v-flex xs12 md5 sm5 py-2>
                        <v-flex xs12 md5 sm12 pb-1 class="subhead3">Scheme Amount</v-flex>
                        <v-text-field
                          autofocus
                          v-model="Monthlydeposit"
                          outlined
                          color="#b234a9"
                          disabled
                          dense
                          hide-details
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 md5 sm5  py-2>
                        <v-flex xs12 md5 sm512 pb-1 class="subhead3"
                          >Months Covered</v-flex
                        >
                        <v-text-field
                          autofocus
                          v-model="Monthlydeposit"
                          outlined
                          color="#b234a9"
                          disabled
                          dense
                          hide-details
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 md5 sm5  py-2>
                        <v-flex xs12 md5 sm512 pb-1 class="subhead3">Start Date</v-flex>
                        <v-menu
                          ref="menu1"
                          v-model="menu1"
                          :close-on-content-click="false"
                          max-width="290"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              clearable
                              outlined
                              dense
                              hide-details=""
                              append-icon="mdi-calendar-multiselect"
                              readonly
                              color="#b234a9"
                              v-model="from"
                              v-bind="attrs"
                              v-on="on"
                              @click:clear="from = null"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="from"
                            color="#571964"
                            @change="menu1 = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-flex>
                      <v-flex xs12 md5 sm5  py-2>
                        <v-flex xs12 md5 sm512 pb-1 class="subhead3">End Date</v-flex>
                        <v-menu
                          ref="menu2"
                          v-model="menu2"
                          :close-on-content-click="false"
                          max-width="290"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              clearable
                              outlined
                              dense
                              hide-details=""
                              append-icon="mdi-calendar-multiselect"
                              readonly
                              color="#b234a9"
                              v-model="from2"
                              v-bind="attrs"
                              v-on="on"
                              @click:clear="from2 = null"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="from2"
                            color="#571964"
                            @change="menu2 = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-flex>
                      <v-flex xs12 md5 sm5  py-2>
                        <v-flex xs12 md5 sm512 pb-1 class="subhead3"
                          >Monthly Deposit</v-flex
                        >
                        <v-text-field
                          autofocus
                          v-model="Monthlydeposit"
                          outlined
                          color="#b234a9"
                          disabled
                          dense
                          hide-details
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 md5 sm5  py-2>
                        <v-flex xs12 md5 sm512 pb-1 class="subhead3"
                          >Monthly Interest</v-flex
                        >
                        <v-text-field
                          autofocus
                          v-model="Monthlydeposit"
                          outlined
                          color="#b234a9"
                          disabled
                          dense
                          hide-details
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <v-layout wrap class="py-4" justify-end>
                  <v-flex xs12 md2 sm2 px-4 pb-3 pb-md-0  class="buttons1">
                    <v-btn block dark color="#3F053C" class="buttons1">
                      Save
                    </v-btn>
                  </v-flex>
                  <v-flex xs12 md2 sm2 px-4>
                    <v-btn class="buttons1" block dark color="#80437C">
                      Cancel
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showsnackbar: false,
      timeout: 2000,
      msg: null,

      showField1: false,
      showField2: false,
      showField3: false,
      showField4: false,
      row: null,
      Schemename: null,
      Monthlydeposit: null,
      MonthlyInterest: null,
      custName: null,
      customerlist: [],
      scName: null,
      menu1: false,
      menu2: false,
      from: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      from2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    };
  },
  mounted() {
    this.getcustomer();
  },
  methods: {
    getcustomer() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/customer/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          page: this.page,
          count: 20,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.customerlist = response.data.data;
            this.Pagelength = Math.ceil(response.data.count / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    // stocklist() {
    //   axios({
    //     url: "/stock/getalllist",
    //     method: "get",
    //     data: {
    //       username: this.username,
    //       email: this.email,
    //       jewelleryName: this.name,
    //       phone: this.phone,
    //       location: this.address,
    //     },
    //     headers: {
    //       "x-auth-token": localStorage.getItem("token"),
    //     },
    //     params: {
    //       page: this.currentpage,
    //       limit: this.limit,
    //       from: this.from,
    //       to: this.to,
    //     },
    //   })
    //     .then((response) => {
    //       this.appLoading = false;
    //       if (response.data.status == true) {
    //         this.array = response.data.data;
    //         this.pages = Math.ceil(response.data.totalLength / this.limit);
    //       } else {
    //         this.msg = response.data.msg;
    //         this.showsnackbar = true;
    //       }
    //     })
    //     .catch((err) => {
    //       this.appLoading = false;
    //       this.ServerError = true;
    //       console.log(err);
    //     });
    // },
  },
};
</script>
